import {
  Nav,
  Shadow,
  Brand,
  Links,
  LinkItem,
  MobileIcons,
  HamburgerIcon,
  NavMenu,
} from './NavBar.style.js';
import { SearchBar } from '../../SearchBar/SearchBar';
import { SearchIcon } from '../../SearchBar/SearchBar.style';
import { useEffect, useState } from 'react';
import { LanguagePicker } from '../../LanguagePicker/LanguagePicker';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import XIcon from '../../../../public/icons/XIcon';
import { NavDropdown } from '../../NavDropdown/NavDropdown';
import navItems from '../../../../public/StaticData/NavItems.json';
import { useRouter } from 'next/router';

export const NavBar = () => {
  const { t } = useTranslation('common');
  const [openLeft, setOpenLeft] = useState(false);
  const [openRight, setOpenRight] = useState(false);
  const router = useRouter();
  const lang = router.locale;

  useEffect(() => {
    openLeft || openRight
      ? (document.documentElement.style.overflow = 'hidden')
      : (document.documentElement.style.overflow = 'auto');
  }, [openLeft, openRight]);

  useEffect(() => {
    if (openLeft || openRight) {
      setOpenLeft(false);
      setOpenRight(false);
    }
    // eslint-disable-next-line
  }, [router.asPath]);

  return (
    <Nav>
      <Shadow
        onClick={() => setOpenRight(false) & setOpenLeft(false)}
        openLeft={openLeft}
        openRight={openRight}
      />
      <NavMenu>
        <MobileIcons
          onClick={() => setOpenLeft(!openLeft) & setOpenRight(false)}
        >
          {(openLeft && (
            <XIcon fill="white" aria-label={t('close menu')} />
          )) || <HamburgerIcon fill="white" aria-label={t('expand menu')} />}
        </MobileIcons>
      </NavMenu>
      <Link passHref href={'/'} tabIndex={0} legacyBehavior>
        {lang === 'es' ? (
          <Brand>
            {t('florentine codex')} <i>{t('digital')}</i>
          </Brand>
        ) : (
          <Brand>
            <i>{t('digital')}</i> {t('florentine codex')}
          </Brand>
        )}
      </Link>
      <SearchBar mobilehide />
      <Links isOpen={openLeft} direction="left">
        <Link passHref href={'/'} legacyBehavior>
          <LinkItem>{t('home')}</LinkItem>
        </Link>
        <NavDropdown title={'codex'} options={navItems.codex} />
        <NavDropdown title={'resources'} options={navItems.resources} />
        <NavDropdown title={'about'} options={navItems.about} />
        <LanguagePicker />
      </Links>
      <NavMenu>
        <MobileIcons
          onClick={() => setOpenRight(!openRight) & setOpenLeft(false)}
        >
          {(openRight && (
            <XIcon fill="white" aria-label={t('close menu')} />
          )) || (
            <SearchIcon aria-label={t('search')} style={{ fill: 'white' }} />
          )}
        </MobileIcons>
      </NavMenu>
      <Links isOpen={openRight} direction="right">
        <SearchBar
          mobileshow="true"
          closeSearch={() => setOpenRight(!openRight) & setOpenLeft(false)}
        />
        {/*<SearchHistory>{t('your previous searches')}</SearchHistory>*/}
      </Links>
    </Nav>
  );
};
