import {
  TileWrapper,
  SectionTitle,
  TileSection,
} from './FrontPageTiles.style.js';
import { Tiles } from '../Tiles/Tiles';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

export const FrontPageTiles = ({ data }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const lang = router.locale;

  const handleLink = (link) => {
    router.push({
      pathname: `/book/${link.book_id}/folio/${link.folio_id}`,
    });
  };
  const handleSearchLink = (link) => {
    link.type === 'text_search'
      ? router.push({
          pathname: '/search',
          locale: lang,
          query: { term: `${link.fulltext}`, view: 'text' },
        })
      : router.push({
          pathname: '/search',
          locale: lang,
          query: {
            tags: `tag|${link.tag_id}|${link.fulltext}`,
            view: 'image',
          },
        });
  };

  return (
    <TileWrapper>
      <SectionTitle> {t('books')} </SectionTitle>
      <TileSection>
        {data.books.map((item, i) => {
          return (
            <Tiles
              key={i}
              type="tile"
              title={item.label[lang]}
              subTitle={item.sublabel && item.sublabel[lang]}
              image={item.thumbnail}
              ariaLabel={t('go to search results')}
              alt={t('illustration of') + ' ' + item.label[lang]}
              links={item.links[lang]}
              onClick={handleLink}
            />
          );
        })}
      </TileSection>

      {Object.entries(data)
        .filter((a) => a[0] !== 'books')
        .map(([key, value], i) => {
          return (
            <React.Fragment key={i}>
              <SectionTitle key={i}> {t(key)} </SectionTitle>
              <TileSection data-is-bricks={key === 'deities'}>
                {value.map((item, i) => {
                  return item.thumbnail ? (
                    <Tiles
                      key={i}
                      type="tile"
                      title={item.label[lang]}
                      subTitle={item.sublabel && item.sublabel[lang]}
                      image={item.thumbnail}
                      ariaLabel={t('go to search results')}
                      alt={t('illustration of') + ' ' + item.label[lang]}
                      links={item.links[lang]}
                      onClick={handleSearchLink}
                    />
                  ) : (
                    <Tiles
                      key={i}
                      type="brick"
                      title={item.label[lang]}
                      ariaLabel={t('go to search results')}
                      links={item.links[lang]}
                      onClick={handleSearchLink}
                    />
                  );
                })}
              </TileSection>
            </React.Fragment>
          );
        })}
    </TileWrapper>
  );
};
