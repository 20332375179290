import styled from 'styled-components';
import SpinnerIcon from '../../../../public/icons/SpinnerIcon';
import { Theme } from '../../../../layout/Basic';

export const BGContainer = styled.div`
  width: 100%;
  height: 100vh;
  text-align: center;
  padding: 5px;
  background-color: ${Theme.color.darkBeige};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 5px;
`;

export const Loader = styled(SpinnerIcon)`
  fill: ${(props) => props.color};
  font-size: 20px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
